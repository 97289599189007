import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-forumbee"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h2>Angry Bee Research</h2>
        <p>
        Our mission at Angry Bee Research is to advance the boundaries of engineering physics through
        innovative research and development. We strive to create cutting-edge technologies that enhance
        the quality of life for people around the world while also promoting sustainability and 
        environmental responsibility. We are committed to fostering a culture of collaboration and 
        excellence, where our team of experts work together to solve complex problems and push the
        limits of what is possible. Through our work, we aim to create a better future for all.          
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('renewableenergy')
            }}
          >
            Renewable
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('nanotechnology')
            }}
          >
            Nanotechnology
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('quantumtechnology')
            }}
          >
            Quantum
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('biomedical')
            }}
          >
            Biomedical
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('astronomy')
            }}
          >
            Astronomy
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
