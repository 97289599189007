import PropTypes from 'prop-types'
import React from 'react'
import biomedical from '../images/bee-biomedical.jpg'
import renewable from '../images/bee_renewable_2.jpg'
import nanotech from '../images/bee-nano.jpg'
import quantum from '../images/bee-qauntum.jpg' 
import astronomy from '../images/bee-astronomy.jpg' 

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="renewableenergy"
          className={`${this.props.article === 'renewableenergy' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Renewable Energy</h2>
          <span className="image main">
            <img src={renewable} alt="" />
          </span>
          <p>
          Renewable energy is a rapidly growing field that offers immense potential for reducing our reliance on fossil fuels and mitigating the impacts of climate change. At Angry Bee Research, we are committed to advancing the frontiers of renewable energy research through innovative and interdisciplinary approaches.
          <br></br><br></br>
          Our research in renewable energy spans a wide range of areas, from solar and wind energy to energy storage and grid integration. Our team of expert engineers, physicists, and researchers work together to develop cutting-edge technologies that can enhance the efficiency, reliability, and sustainability of renewable energy systems.
          <br></br><br></br>
          Some of our current research areas include:
          <br></br><br></br>
          <ul>
            <li>
            Solar energy: We are exploring new materials and device architectures for solar cells that can improve efficiency and reduce costs. Our research also focuses on developing novel solar tracking systems and integrating solar energy with other renewable energy sources.
            </li>
            <li>
            Wind energy: We are investigating new designs for wind turbines that can improve power output and reduce noise and vibration. Our research also focuses on developing advanced control systems for wind farms and improving grid integration.
            </li>
            <li>
            Energy storage: We are developing new materials and technologies for energy storage, such as advanced batteries and supercapacitors. Our research also focuses on optimizing energy storage systems for different applications, such as grid-level storage and electric vehicles.
            </li>
            <li>
            Grid integration: We are developing new strategies for integrating renewable energy into the grid, such as demand response and energy management systems. Our research also focuses on developing advanced modeling and simulation tools to optimize grid integration.
            </li>          
          </ul>
          We believe that renewable energy has the potential to transform the way we live and work, and we are committed to making this vision a reality through cutting-edge research and innovation.          
          </p>
          {close}
        </article>

        <article
          id="nanotechnology"
          className={`${this.props.article === 'nanotechnology' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Nanotechnology</h2>
          <span className="image main">
            <img src={nanotech} alt="" />
          </span>
          <p>
          Nanotechnology is an exciting and rapidly evolving field that involves the manipulation and engineering of materials at the nanoscale. We are at the forefront of nanotechnology research and development, using our expertise in engineering physics to create novel and innovative solutions to complex challenges.
          <br></br><br></br>
          Our research in nanotechnology spans a wide range of areas, from advanced materials and devices to biomedical engineering and quantum technology. We collaborate with leading researchers and industry partners to develop cutting-edge technologies that have the potential to revolutionize a wide range of applications.
          <br></br><br></br>
          Some of our current research areas include:
          <br></br><br></br>
          <ul>
            <li>
            Nanomaterials: We are developing new nanomaterials with unique properties and applications, such as lightweight and strong nanocomposites, high-capacity energy storage materials, and advanced sensors.
            </li>
            <li>
            Nanoelectronics: We are developing new approaches to nanoelectronics, such as quantum computing and nanophotonics, which have the potential to revolutionize computing and information technology.
            </li>
            <li>
            Biomedical engineering: We are using nanotechnology to develop new approaches to drug delivery, medical imaging, and tissue engineering. Our research focuses on creating new materials and devices that can improve patient outcomes and reduce healthcare costs.
            </li>
            <li>
            Environmental and energy applications: We are exploring new ways to use nanotechnology to address environmental and energy challenges, such as water purification and energy conversion. Our research focuses on developing sustainable and scalable solutions that can have a positive impact on society and the environment.
            </li>          
          </ul>
          We are passionate about using nanotechnology to create a better future for all. We are committed to advancing the frontiers of nanotechnology research and development and collaborating with others to translate our research into real-world applications.
          </p>          
          {close}
        </article>

        <article
          id="quantumtechnology"
          className={`${this.props.article === 'quantumtechnology' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Quantum Technology</h2>
          <span className="image main">
            <img src={quantum} alt="" />
          </span>
          <p>
          Quantum technology is a rapidly advancing field that is transforming the way we think about computing, sensing, and communication. We are pushing the boundaries of quantum technology research and development, leveraging our expertise in engineering physics to create groundbreaking solutions to some of the most challenging problems in the field.
          <br></br><br></br>
          Our research in nanotechnology spans a wide range of areas, from advanced materials and devices to biomedical engineering and quantum technology. We collaborate with leading researchers and industry partners to develop cutting-edge technologies that have the potential to revolutionize a wide range of applications.
          <br></br><br></br>
          Some of our current research areas include:
          <br></br><br></br>
          <ul>
            <li>
            Quantum computing: We are working to develop new algorithms and hardware for quantum computers that can solve complex problems beyond the capabilities of classical computers. Our research focuses on developing scalable and fault-tolerant quantum computing architectures that can be used to solve real-world problems in finance, materials science, and more.
            </li>
            <li>
            Quantum sensing: We are using quantum technology to create high-precision sensors that can detect and measure a wide range of physical quantities, such as magnetic fields, temperature, and pressure. Our research focuses on developing new materials and devices that can improve the sensitivity and accuracy of quantum sensors.
            </li>
            <li>
            Quantum communication: We are developing new approaches to quantum communication that can enable secure and efficient transfer of information over long distances. Our research focuses on developing quantum key distribution protocols and quantum repeaters that can overcome the limitations of traditional communication methods.
            </li>                    
          </ul>
          We are dedicated to advancing the frontiers of quantum technology research and development. We believe that quantum technology has the potential to transform the way we live and work, and we are committed to making this vision a reality through cutting-edge research and innovation.
          </p>
          {close}
        </article>
        <article
          id="biomedical"
          className={`${this.props.article === 'biomedical' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Biomedical Technology</h2>
          <span className="image main">
            <img src={biomedical} alt="" />
          </span>
          <p>
          Biomedical technology is a field that involves the application of engineering and physics principles to solve complex problems in medicine and biology. We are leveraging our expertise in engineering physics to develop innovative solutions for a wide range of biomedical challenges.
          <br></br><br></br>
          Our research in biomedical technology spans a wide range of areas, from medical imaging and sensing to drug delivery and tissue engineering. We collaborate with leading researchers and industry partners to develop cutting-edge technologies that have the potential to revolutionize the field of medicine.
          <br></br><br></br>
          Some of our current research areas include:
          <br></br><br></br>
          <ul>
            <li>
            Medical imaging: We are developing new imaging technologies that can provide detailed and accurate images of biological tissues and structures. Our research focuses on developing new approaches to magnetic resonance imaging (MRI), computed tomography (CT), and positron emission tomography (PET) that can improve patient outcomes and reduce healthcare costs.
            </li>
            <li>
            Biomedical sensing: We are developing new sensors and devices that can monitor a wide range of physiological signals, such as heart rate, blood pressure, and glucose levels. Our research focuses on developing sensors that are non-invasive, comfortable, and easy to use, and that can provide accurate and reliable data for clinical decision-making.
            </li>
            <li>
            Drug delivery: We are using engineering and physics principles to develop new approaches to drug delivery that can improve the efficacy and safety of pharmaceuticals. Our research focuses on developing new materials and devices that can target specific tissues or cells, and that can release drugs in a controlled and sustained manner.
            </li>
            <li>
            Tissue engineering: We are using engineering and physics principles to develop new approaches to tissue engineering that can create functional and biocompatible tissues and organs. Our research focuses on developing new materials and techniques for growing tissues and organs in the laboratory, and on developing strategies for implanting these tissues and organs into patients.
            </li>          
          </ul>
          We are committed to advancing the frontiers of biomedical technology research and development. We believe that our research has the potential to improve the health and well-being of people around the world, and we are dedicated to making this vision a reality through cutting-edge research and innovation.
          </p>
          {close}
        </article>
        <article
          id="astronomy"
          className={`${this.props.article === 'astronomy' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Astronomy</h2>
          <span className="image main">
            <img src={astronomy} alt="" />
          </span>
          <p>
          Astronomy technology is a field that involves the application of engineering and physics principles to explore and study the universe. We are leveraging our expertise in engineering physics to develop innovative solutions for a wide range of astronomy challenges.
          <br></br><br></br>
          Our research in astronomy technology spans a wide range of areas, from telescopes and detectors to data analysis and visualization. We collaborate with leading researchers and industry partners to develop cutting-edge technologies that have the potential to transform our understanding of the universe.
          <br></br><br></br>
          Some of our current research areas include:
          <br></br><br></br>
          <ul>
            <li>
            Telescopes and detectors: We are developing new telescopes and detectors that can provide high-resolution images and detailed data on celestial objects. Our research focuses on developing new approaches to telescope design, such as using interferometry and adaptive optics, as well as developing new detectors that can operate at high frequencies and sensitivities.
            </li>
            <li>
            Data analysis and visualization: We are developing new approaches to data analysis and visualization that can enable researchers to extract meaningful insights from large and complex data sets. Our research focuses on developing new algorithms and software tools that can analyze data from multiple telescopes and sensors, as well as developing new visualization techniques that can help researchers to explore and understand complex data sets.
            </li>
            <li>
            Astrophysics: We are developing new approaches to astrophysics research that can enable researchers to study the properties and behavior of celestial objects. Our research focuses on developing new models and simulations of stellar and galactic evolution, as well as developing new methods for analyzing the properties of gravitational waves and other exotic astrophysical phenomena.
            </li>                    
          </ul>
          We are dedicated to advancing the frontiers of astronomy technology research and development. We believe that our research has the potential to transform our understanding of the universe and to pave the way for new discoveries and breakthroughs in astronomy.
          </p>
          {close}
        </article>
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <p>
          Welcome to Angry Bee Research, a cutting-edge engineering physics research company located in Edmonton Alberta dedicated to advancing the frontiers of science and technology. Our team of expert engineers, physicists, and researchers are committed to pushing the limits of what is possible and developing innovative solutions to complex problems.
          <br></br><br></br>
          At Angry Bee, we are driven by a passion for discovery and a commitment to creating a better future for all. Our research spans a wide range of areas, from renewable energy and materials science to quantum technology and biomedical engineering. We believe that by working at the intersection of different disciplines, we can unlock new insights and develop groundbreaking technologies that have the potential to transform the world.
          <br></br><br></br>
          Our team is comprised of individuals with diverse backgrounds and expertise, united by a shared sense of purpose and a dedication to excellence. We value collaboration, open communication, and a culture of continuous learning and improvement. We believe that by fostering a supportive and inclusive environment, we can bring out the best in our team and achieve great things together.
          <br></br><br></br>
          Thank you for taking the time to learn more about Angry Bee. We invite you to explore our website and discover more about our research, our team, and our vision for the future. If you have any questions or would like to learn more about our work, please don't hesitate to contact us.

          </p>
          {close}
        </article>
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          Thank you for your interest in Angry Bee Research. We value your feedback and look forward to hearing from you. There are several ways to get in touch with us:
          <br></br><br></br>
          <ul>
          <li>General inquiries: For general inquiries or questions about our research and services, please email us at Questions@AngryBee.ca. We will do our best to respond to your message as soon as possible.</li>
          <li>Collaboration opportunities: If you are interested in partnering with us or exploring collaboration opportunities, please email us at Collab@AngryBee.ca. We welcome partnerships with other research organizations, academic institutions, and industry partners.</li>
          <li>Media inquiries: For media inquiries or requests for interviews or information, please email us at Questions@AngryBee.ca. We are happy to provide expert commentary or insights on topics related to our research areas.</li>
          </ul>
          Thank you for considering Angry Bee. We look forward to hearing from you!

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
